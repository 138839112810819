import React from "react";

import PostCard from "./PostCard";

const PostCardRoll = ({ posts }) => {
    return (
        <section className="post-feed">
            {posts.map(({ node }) => (
                <PostCard key={node.id} post={node} />
            ))}
        </section>
    );
};

export default PostCardRoll;
